import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableArea from "../../components/DataTable/TableArea";
import {
  getCenterNames,
  isActionAllowed,
  onChangeInputData,
} from "../../utilities/helpers";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import {
  balanceDetailsFixture,
  balanceDetailsFormFixture,
  balanceFilterFixture,
  balanceFilterFormFixture,
  previousBalanceTableHFixture,
} from "./utilities/fixture";
import validate from "../../utilities/validation";

const PreviousBalance = () => {
  const [logInUser] = useState(JSON.parse(localStorage.getItem("user")) || {});
  const [loading, setLoading] = useState(false);
  const [filterDetails, setFilterDetails] = useState(
    balanceFilterFixture(logInUser)
  );
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [centerNames, setCenterNames] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [balanceDetails, setBalanceDetails] = useState(
    balanceDetailsFixture(logInUser)
  );
  const [filterApplied, applyFilter] = useState(false);
  const [modalHead, setModalHead] = useState("");

  const handlePageSelect = (selected) => {
    if (filterApplied) getPreviousBalance(selected, filterDetails);
    else getPreviousBalance(selected);
  };

  const getPreviousBalance = async (page_num, filterData = false) => {
    if (loading) return;
    setLoading(true);
    const { res, data } = await postDataWithAuth({
      api_url: page_num
        ? endpoints["getPreviousBalance"](page_num)
        : endpoints["getPreviousBalance"](),
      data: filterData ? filterDetails : {},
    });
    setLoading(false);
    if (data) {
      setData(data);
      if (filterData) applyFilter(true);
      if (res?.pagination) setPaginationData(res.pagination);
      if (openModal) setOpenModal(false);
    }
  };

  const handleEdit = (data) => {
    setModalHead("Update Balance");
    setBalanceDetails(data);
    setOpenModal("edit");
  };

  const handleModalOpen = (action = true) => {
    if (action === "add") {
      setModalHead("Add Balance");
      setBalanceDetails(balanceDetailsFixture(logInUser));
    } else if (action === "filter") {
      setModalHead(`Filters`);
    }
    setOpenModal(action);
  };

  const handleModalClose = () => {
    if (openModal === "add" || openModal === "edit")
      setBalanceDetails(balanceDetailsFixture(logInUser));

    setOpenModal(false);
  };

  const handleInputChange = (e) => {
    onChangeInputData(e, balanceDetails, setBalanceDetails);
  };

  const handleSubmit = async () => {
    if (!loading && handleValidation()) {
      setLoading(true);
      let body = {};
      for (let key in balanceDetailsFixture()) {
        body[key] = balanceDetails[key];
      }
      if (balanceDetails["id"]) body["id"] = balanceDetails["id"];
      const { success, message } = await postDataWithAuth({
        api_url: balanceDetails["id"]
          ? endpoints["updatePreviousBalance"]
          : endpoints["addPreviousBalance"],
        data: body,
      });
      setLoading(false);
      if (success) {
        handleModalClose();
        getPreviousBalance(paginationData?.current_page);
        toast.success(message);
      }
    }
  };

  const handleValidation = () => {
    let validation = true;
    let req_fields = ["centerId", "activity_date", "comment"];
    for (let i = 0; i < req_fields?.length; i++) {
      let key = req_fields[i];
      let err = validate[key](balanceDetails[key]);
      if (err) {
        validation = false;
        toast.error(err);
        break;
      }
    }
    return validation;
  };

  const filterData = () => {
    let valid = true;
    let from = filterDetails["from_date"];
    let to = filterDetails["to_date"];
    if (from && to && to < from) {
      valid = false;
      toast.info("From Date Should Be Less Than To Date");
    } else if ((!from && to) || (from && !to)) {
      valid = false;
      let msg = `${
        filterDetails["from_date"] ? "Select To Date" : "Select From Date"
      }`;
      toast.info(msg);
    }
    if (valid) getPreviousBalance(paginationData?.current_page, true);
  };

  const handleReset = () => {
    setFilterDetails(balanceFilterFixture(logInUser));
    applyFilter(false);
    getPreviousBalance(1);
  };

  useEffect(() => {
    async function fetchData() {
      const centerData = await getCenterNames();
      if (centerData) setCenterNames(centerData);
      await getPreviousBalance();
    }
    fetchData();
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading="Previous Balance"
          tableHeadersFixture={previousBalanceTableHFixture}
          data={data}
          loading={loading}
          startSerialNum={paginationData?.from}
          onEdit={(rowData) => handleEdit(rowData)}
          showPagination={true}
          onPageSelect={(selected) => handlePageSelect(selected)}
          paginationData={paginationData}
          perPage={paginationData?.per_page}
        >
          <div className="button-group filter-button justify-content-md-end">
            <button
              type="button"
              className="btn btn-primary btn-primary-border"
              onClick={() => handleModalOpen("filter")}
            >
              Filters
            </button>
            {isActionAllowed("create") ? (
              <span
                className="btn btn-primary btn-add"
                onClick={() => handleModalOpen("add")}
              >
                Add
              </span>
            ) : null}
          </div>
        </TableArea>
      </main>

      <Modal
        open={openModal && !loading}
        onClose={handleModalClose}
        header={modalHead}
        modal_classes="filter-popup-medium"
      >
        {openModal === "filter" ? (
          <Form
            fixture={balanceFilterFormFixture(
              { centerNames: centerNames },
              logInUser
            )}
            inputs={filterDetails}
            loading={loading}
            onChange={(e) =>
              onChangeInputData(e, filterDetails, setFilterDetails)
            }
            onSubmit={filterData}
            handleBack={handleReset}
            nextBtnText="Search"
            nextBtnClasses="btn-search"
            backBtnText="Reset"
            backBtnClasses="btn-primary-border btn-reset"
            BtnParentClasses="justify-content-end mt-3"
            showBackBtn={true}
          />
        ) : (
          <Form
            fixture={balanceDetailsFormFixture(
              { centerNames: centerNames },
              logInUser
            )}
            inputs={balanceDetails}
            loading={loading}
            onChange={handleInputChange}
            onSubmit={handleSubmit}
            handleBack={handleModalClose}
            showBackBtn={true}
            backBtnText="Close"
            backBtnClasses="btn-primary-border"
            nextBtnText={`${balanceDetails["id"] ? "Update" : "Save"}`}
            BtnParentClasses="justify-content-end mt-3"
          />
        )}
      </Modal>
    </>
  );
};

export default PreviousBalance;
