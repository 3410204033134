export const produceToCwcTableFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Name", key: "name", profile: true },
  { text: "Unique Id", key: "uniqueId" },
  { text: "CWC Case No", key: "cwccaseno" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Registered By", objkey: "user_data", key: "name" },
  { text: "Produced By", objkey: "user_data", key: "name" },
  { text: "Comments", key: "comments" },
  { text: "Produced Date", objkey: "child_center", key: "transfer_date" },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },
  {
    text: "Status",
    fn: (rowData) =>
      rowData["produce_cwc"] ? (
        <span className="tag-corner">Produced to CWC</span>
      ) : rowData["refer_cwc"] ? (
        <span className="tag-corner">Referred from CWC</span>
      ) : (
        <span className="tag-corner">New Corner</span>
      ),
  },
  {
    text: "Action",
    action: true,
    key: ["goTo", "edit", "delete"],
    actionFor: "cwc",
  },
];

export const filterInputFixture = (data) => ({
  name: "",
  centerdetailId: data?.centerId || "",
  uniqueId: "",
  from_date: "",
  to_date: "",
});

export const docsFormFixture = (optionData, isProduce = false) => {
  return [
    {
      inputProps: {
        type: "searchableSelect",
        className: "form-control",
        placeholder: "Select Tag",
        name: "tagId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      optionProps: { textKey: "name", valueKey: "id" },
      options: optionData?.tagNames ? [...optionData.tagNames] : [],
      label: {
        text: "Tag Name",
        labelProps: { htmlFor: "tagId", className: "" },
      },
      row: "new",
      required: isProduce ? false : true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "doc_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Document's Date",
        labelProps: { htmlFor: "doc_date", className: "" },
      },
      required: isProduce ? false : true,
    },
    {
      parentProps: {
        input: { className: "form-group mb-4 registration-form" },
      },
      inputProps: {
        type: "file",
        className: "fileChoose",
        name: "filename",
        id: "formFileLg",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Document",
        labelProps: { htmlFor: "filename", className: "" },
      },
      required: isProduce ? false : true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comment",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: isProduce ? false : true,
    },
  ];
};

export const FilterFormFixture = (optionData, userDetails) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerdetailId",
      },
      parentClassName: "row mb-3",
      className: "col-12 col-sm-6",
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerdetailId", className: "" },
      },
      readOnlyField: userDetails?.centerId === 1 ? false : true,
      row: "new",
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter Child Name",
        name: "name",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Child Name",
        labelProps: { htmlFor: "name", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "from_date",
      },
      parentClassName: "row mb-3",
      className: "col-12 col-sm-6",
      label: {
        text: "From",
        labelProps: { htmlFor: "from_date", className: "" },
      },
      required: true,
      row: "new",
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "to_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "to",
        labelProps: { htmlFor: "to_date", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter Unique Id",
        name: "uniqueId",
      },
      parentClassName: "row mb-3",
      className: "col-12 col-sm-6",
      label: {
        text: "Unique Id",
        labelProps: { htmlFor: "uniqueId", className: "" },
      },
      row: "new",
    },
  ];
};

// Refer CWC fixtures

export const referToCwcTableFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Name", key: "name", profile: true },
  { text: "Unique Id", key: "uniqueId" },
  { text: "CWC Case No", key: "cwccaseno" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Registered By", objkey: "user_data", key: "name" },
  { text: "Referred By", objkey: "user_data", key: "name" },
  { text: "Comments", key: "comments" },
  { text: "Referred Date", objkey: "child_center", key: "transfer_date" },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },
  {
    text: "Status",
    fn: (rowData) =>
      rowData["produce_cwc"] ? (
        <span className="tag-corner">Produced to CWC</span>
      ) : rowData["refer_cwc"] ? (
        <span className="tag-corner">Referred from CWC</span>
      ) : (
        <span className="tag-corner">New Corner</span>
      ),
  },
  {
    text: "Action",
    action: true,
    key: ["goTo", "edit", "delete"],
    actionFor: "cwc",
  },
];

export const addProduceFormFixture = [
  {
    inputProps: {
      type: "text",
      isdatefield: true,
      className: "form-control border-right-0",
      placeholder: "DD-MM-YY",
      name: "transfer_date",
    },
    parentClassName: "row",
    className: "col-12 col-sm-6",
    label: {
      text: "Produce Date",
      labelProps: { htmlFor: "transfer_date", className: "" },
    },
    required: true,
    row: "new",
  },
  {
    inputProps: {
      type: "textarea",
      className: "form-control",
      placeholder: "Write here ...",
      name: "comment",
    },
    parentClassName: "row",
    className: "col-12 col-sm-6",
    parentProps: { input: { className: "form-group mb-4" } },
    label: {
      text: "Produce Comments",
      labelProps: { htmlFor: "comment", className: "" },
    },
    required: true,
    row: "new",
  },
];

export const addProduceInputFixture = {
  transfer_date: "",
  comment: "",
};

export const addReferFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerId",
      },
      parentClassName: "row mb-3",
      className: "col-12 col-sm-6",
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerId", className: "" },
      },
      required: true,
      row: "new",
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter CWC Case No.",
        name: "cwccaseno",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "CWC Case No.",
        labelProps: { htmlFor: "cwccaseno", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      parentClassName: "row",
      className: "col-12 col-sm-6",
      label: {
        text: "Refer Date",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
      required: true,
      row: "new",
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      parentClassName: "row",
      className: "col-12 ",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Referred Comments",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
      row: "new",
    },
  ];
};

export const addReferInputFixture = {
  centerId: "",
  cwccaseno: "",
  transfer_date: "",
  comment: "",
};
